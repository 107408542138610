$primary: #E23A3A;
$secondary: #FFC9C9;
$success: #33cc66;
$danger: #cc3333;
$info: #33ccee;
$body-color: #333;
$buttons: (
    'secondary': ('background-color': $secondary,
        'text-color': #C81717,
        'shadow-color': #FFECEC,
    )
);
$btn-secondary-bg: $secondary;
$btn-secondary-text: $primary;
$breadcrumb-divider: quote("|");
$nav-pills-link-padding-x: 1rem;
$nav-pills-link-font-size: .825rem;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: rgba($primary, 0.1);
$nav-pills-link-hover-bg: none;
$nav-pills-margin: 0;
$nav-pills-link-border-radius: 0;
$accordion-button-padding-x: 1rem;

// mdb
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/functions';
@import '~mdb-react-ui-kit/dist/scss/free/functions';
@import '~mdb-react-ui-kit/dist/scss/free/variables';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/variables';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/maps';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/mixins';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/utilities';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/root';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/reboot';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/type';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/images';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/containers';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/grid';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/tables';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/forms';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/buttons';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/transitions';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/dropdown';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/button-group';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/nav';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/navbar';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/card';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/breadcrumb';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/pagination';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/badge';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/alert';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/accordion';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/progress';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/placeholders';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/list-group';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/close';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/toasts';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/modal';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/popover';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/carousel';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/spinners';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/offcanvas';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/tooltip';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/helpers';
@import '~mdb-react-ui-kit/dist/scss/free/utilities';
@import '~mdb-react-ui-kit/dist/scss/bootstrap-rtl-fix/utilities/api';
@import '~mdb-react-ui-kit/dist/scss/free/mixins';
@import '~mdb-react-ui-kit/dist/scss/free/utilities';
@import '~mdb-react-ui-kit/dist/scss/free/root';
@import '~mdb-react-ui-kit/dist/scss/free/reboot';
@import '~mdb-react-ui-kit/dist/scss/free/type';
@import '~mdb-react-ui-kit/dist/scss/free/colors';
@import '~mdb-react-ui-kit/dist/scss/free/shadows';
@import '~mdb-react-ui-kit/dist/scss/free/flag';
@import '~mdb-react-ui-kit/dist/scss/free/images';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-control';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-select';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-check';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-file';
@import '~mdb-react-ui-kit/dist/scss/free/forms/input-group';
@import '~mdb-react-ui-kit/dist/scss/free/forms/validation';
@import '~mdb-react-ui-kit/dist/scss/free/forms/form-range';
@import '~mdb-react-ui-kit/dist/scss/free/tables';
@import '~mdb-react-ui-kit/dist/scss/free/buttons';
@import '~mdb-react-ui-kit/dist/scss/free/deprecated';
@import '~mdb-react-ui-kit/dist/scss/free/dropdown';
@import '~mdb-react-ui-kit/dist/scss/free/button-group';
@import '~mdb-react-ui-kit/dist/scss/free/nav';
@import '~mdb-react-ui-kit/dist/scss/free/navbar';
@import '~mdb-react-ui-kit/dist/scss/free/card';
@import '~mdb-react-ui-kit/dist/scss/free/breadcrumb';
@import '~mdb-react-ui-kit/dist/scss/free/pagination';
@import '~mdb-react-ui-kit/dist/scss/free/badge';
@import '~mdb-react-ui-kit/dist/scss/free/alert';
@import '~mdb-react-ui-kit/dist/scss/free/progress';
@import '~mdb-react-ui-kit/dist/scss/free/list-group';
@import '~mdb-react-ui-kit/dist/scss/free/close';
@import '~mdb-react-ui-kit/dist/scss/free/modal';
@import '~mdb-react-ui-kit/dist/scss/free/toasts';
@import '~mdb-react-ui-kit/dist/scss/free/tooltip';
@import '~mdb-react-ui-kit/dist/scss/free/popover';
@import '~mdb-react-ui-kit/dist/scss/free/scrollspy';
@import '~mdb-react-ui-kit/dist/scss/free/ripple';
@import '~mdb-react-ui-kit/dist/scss/free/range';
@import '~mdb-react-ui-kit/dist/scss/free/accordion';
// @import '~mdb-react-ui-kit/dist/scss/free/carousel';

// @import "~mdb-react-ui-kit/dist/scss/mdb.free";


html {
    height: 100%;
    font-size: 16px;
}

body {
    margin: 0;
    min-width: 320px;
    height: 100%;
    overflow: auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[lang|="en"] .monospace {
    font-family: monospace;
}

[lang|="zh"] body {
    font-family: -apple-system, BlinkMacSystemFont, "Microsoft Yahei", "SimHei", "PingFang SC", "STHeiti", sans-serif;
}

[lang="zh-TW"] body {
    font-family: -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "PingFang TC", sans-serif;
}

[lang="zh-HK"] body {
    font-family: -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "PingFang HK", sans-serif;
}

form {
    margin: 0;
}

#root {
    height: 100%;
}

.invalid-feedback,
.valid-feedback {
    font-size: 12px;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
    margin-bottom: 0;
}

.loading-modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
}

.loading.global {
    display: flex;
    position: fixed;
    left: calc(50% - 3rem);
    top: calc(50% - 3rem);
    z-index: 10000;
    width: 6rem;
    height: 6rem;
    min-width: 6rem !important;
    min-height: 6rem !important;
    max-width: none !important;
    max-height: none !important;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    color: #fff;
    font-size: 2.5rem;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.loading.fa {
    --fa-display: flex;
    text-align: center;
    margin: 0 auto;
    align-self: center;
    justify-self: center;
    background: none;
    position: static;
    width: 3em;
    height: 3em;
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .75)
    }

    .current {
        position: relative;
        left: 1.5rem;
        top: 1.5rem;
        z-index: 1003;
        width: calc(100% - 3rem);
        height: calc(100% - 3rem);
        object-fit: contain;
        user-select: none;
        pointer-events: none;
    }

    .list {
        position: absolute;
        bottom: 1.5rem;
        left: 1.5rem;
        z-index: 1002;
        width: calc(100% - 3rem);
        height: 82px;
        height: min(82px, calc(10vh + 2px));
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        column-gap: .5rem;
        overflow: auto;

        li {
            border: 1px solid transparent;
            cursor: pointer;

            &.active {
                border-color: $primary;
            }
        }

        img {
            object-fit: contain;
            height: 80px;
            height: min(80px, 10vh);
            width: 80px;
            width: min(80px, 10vh);
        }

        &+.current {
            height: calc(100% - 4.5rem - 82px);
            height: calc(100% - 4.5rem - min(80px, 10vh) - 2px);
        }
    }
}

.form-input {
    display: flex;
    align-items: stretch;

    select.input-group-text {
        width: auto;
        padding-right: $input-padding-right !important;

        &:not(:last-child) {
            border-right: 1px solid $input-group-addon-border-color;
        }
    }

    .input-group-text {
        border-top-color: transparent;
        border-bottom-color: transparent;

        &:not(.form-control + .input-group-text) {
            border-left: 0;
        }

        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }
}

.form-outline select.form-control:not([multiple]) {
    padding-right: $input-padding-right * 2;

    &+.fa {
        position: absolute;
        top: 50%;
        right: $input-padding-right;
        transform: translate(50%, -50%);
        pointer-events: none;
    }
}

.auto-complete {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 10;
    width: 100%;
    max-height: 500%;
    overflow: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
    border-radius: 0 0 4px 4px;

    li {
        padding: .25em .75em;
        cursor: pointer;
        background-color: #fff;

        &:hover {
            background: #f5f5f5;
        }
    }
}

#modal-resource {
    --mdb-modal-zindex: 100000;
}

.resource-picker {
    border: 0;
    padding: 0;
    background: none;
    position: relative;
    width: 6.25rem;
    height: 6.25rem;
    display: flex;
    flex-direction: column;

    .btns {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
    }

    &:hover .btns {
        display: flex;
    }

    button {
        flex: 1 0 auto;
        border: 0;
        padding: 0;
        background: none;
        color: inherit;
        line-height: 1.6875;

        &:hover,
        &:focus {
            background: rgba(0, 0, 0, 0.3);
        }
    }

    img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border: 1px solid #d5d5d5;
    }
}

.toast {
    position: fixed;
    display: block !important;
    background: rgba(0, 0, 0, 0.8);
    left: 50%;
    z-index: 1000;
    width: auto;
    max-width: 80vw;
    padding: .25rem .5rem;
    opacity: 0;
    transition: opacity .3s;

    .icon {
        display: block;
        margin-bottom: 0.2rem;
        font-size: 300%;
        text-align: center;
    }

    &.show {
        opacity: 1;
    }

    &.top {
        top: 20%;
        transform: translate(-50%, 0);
    }

    &.bottom {
        bottom: 20%;
        transform: translate(-50%, 0);
    }

    &.center {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.login-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: var(--mdb-gray);

    .card {
        flex: 0 0 calc(20rem - var(--mdb-gutter-x, .75rem));
        max-width: calc(20rem - var(--mdb-gutter-x, .75rem));
    }

    .captcha {
        position: absolute;
        right: $input-padding-right;
        top: calc(#{$input-padding-top} + 1px);
        z-index: 2;
        width: 3.75rem;
        height: 1.5rem;
        cursor: pointer;
        transition: transform .3s;

        &:hover {
            transform: scale(1.25);
        }

        &+.form-outline input {
            padding-right: $input-padding-right * 2 + 3.75;
        }
    }

    .quick-search {
        position: absolute;
        bottom: .5rem;
        right: .5rem;
        background: none;
        border: none;
        color: #fff;
        line-height: 1;
        padding: .75em 1em;

        &:hover,
        &:focus {
            color: $primary;
        }
    }
}

.header {
    height: 3rem;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-template-columns: 1fr auto 1fr;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .05);

    .tools {
        display: flex;

        .dropdown-toggle::after {
            display: none;
        }

        .btn {
            padding: 1em;
            font-size: 1rem;
            line-height: 1;
            background: none;

            &+.btn {
                margin-left: .5em;
            }
        }

        &:last-child {
            .btn {
                padding: 1em .5em;
            }

            &>.btn:last-child,
            &>:last-child .btn {
                padding-right: 1em;
            }
        }
    }

    .logo {
        width: 3rem;
        object-fit: contain;
        margin: 0 calc(var(--mdb-gutter-x, .75rem) + #{$card-spacer-x}) 0 0;
        mix-blend-mode: difference;
        padding: .5rem;
    }

    .breadcrumb {
        margin: 0;
        align-items: center;
        height: 100%;
    }
}

.nav-container {
    height: calc(100% - 3rem);
    width: 20rem;
    position: absolute;
    left: -20rem;
    top: 3rem;
    z-index: 20;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, .05);
    transition: left .6s;

    .mask {
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -2;
        display: none;
    }

    &.active {
        left: 0;

        .mask {
            display: block;
        }
    }

    .nav {
        width: 100%;
        height: 100%;
        background-color: #fff;
    }

    .nav-title {
        width: 100%;
        padding: .5rem var(--mdb-gutter-x, .75rem);
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;

        .btn {
            padding: 0;
            font-size: 150%;
            line-height: 1;
            background: none;
            transition: transform .3s;

            &:hover {
                transform: rotate(180deg);
            }
        }
    }

    .nav-item {
        width: 100%;
        margin: 0;

        .nav {
            position: absolute;
            top: 0;
            left: -20rem;
            transition: left .3s;
            width: 20rem;
            box-shadow: 3px 0 5px rgba(0, 0, 0, .05) inset, 0 3px 3px rgba(0, 0, 0, .1);

            &.active {
                left: 0;
            }
        }
    }

    .nav-link {
        position: relative;
        padding: .5rem var(--mdb-gutter-x, .75rem);
        text-align: left;
        cursor: pointer;

        .tip {
            font-size: .875rem;
            line-height: 1;
            display: inline-block;
            margin-left: .5em;
        }
    }
}

.main-content {
    height: 100%;
    background-color: var(--mdb-gray-100);
    padding: calc(3rem + var(--mdb-gutter-y, 0.75rem)) var(--mdb-gutter-x, 0.75rem) var(--mdb-gutter-y, 0.75rem);
    position: relative;
    overflow: auto;

    &>.row {
        margin-right: 0;
    }
}

.grid {
    display: flex;
    flex-direction: column;
    height: 100%;

    .grid-content {
        flex: 1 1 0;

        .table-responsive {
            height: 100%;
        }
    }

    td.empty {
        text-align: center;
        padding: 6.25rem 0;
    }

    img {
        width: 6.25rem;
        height: 6.25rem;
        object-fit: cover;
    }

    .filter-toggle {
        order: -1;
        background: #fff;
        color: $form-label-color;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        box-shadow: none;
        border-radius: $card-border-radius $card-border-radius 0 0;
        border-bottom: 1px solid $table-border-color;
        padding: var(--mdb-gutter-y, 0.75rem) var(--mdb-gutter-x, 0.75rem);
    }

    .filter {
        background: #fff;
        padding: 0 var(--mdb-gutter-x, 0.75rem);
        border-radius: 0 0 $card-border-radius $card-border-radius;

        form {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: var(--mdb-gutter-y, 0.75rem) 0;
            gap: .75rem;

            &>* {
                flex: 0 0 100%;
            }
        }

        &:not(.show)+.filter-toggle {
            border-radius: $card-border-radius;
        }
    }

    th .btn {
        padding: 0;
        font-size: inherit;

        &>svg {
            margin: 0 0 0 .5em;
        }
    }

    tr> :first-child {
        position: sticky;
        left: 0;
        width: 1px;
        white-space: nowrap;
        background: #fff;
    }

    .check {
        input {
            margin-right: 0;
        }

        &+* {
            position: sticky;
            left: $table-cell-padding-x * 2 + $form-check-input-width-md;
            width: 1px;
            white-space: nowrap;
        }
    }

    .action {
        width: 1px;
        white-space: nowrap;
    }

    .w-1 {
        width: 1px;
    }

    .pager {
        --mdb-card-spacer-y: 0.75rem;
        --mdb-card-spacer-x: 0.75rem;

        .card-body:empty {
            display: none;
        }
    }
}

.edit {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    textarea.form-control {
        height: 10rem;
        resize: none;
    }

    .accordion {
        flex: 1 1 auto;
        --mdb-accordion-body-padding-x: 1rem;

        .accordion-body {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;

            &>label {
                display: flex;
                align-items: flex-start;
                column-gap: 1ch;

                &>.label {
                    display: none;
                    line-height: 1.2;
                    min-height: 2.1825rem;
                    flex: 0 0 9.735rem;
                    align-items: center;
                }
            }
        }
    }

    .tools {
        position: sticky;
        bottom: 0;

        .card-body {
            display: flex;
            gap: 1rem;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            --mdb-card-spacer-y: 1rem;
            --mdb-card-spacer-x: 1rem;
        }

        .btns {
            display: flex;
            gap: 1rem;
        }
    }
}

.bill {
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    padding: .75rem;

    &.dialog {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 100;
        min-width: 300px;
        transform: translate(-50%, -50%);
        background-color: $card-bg;
        border-radius: .5rem;
        max-width: calc(100vw - $card-spacer-x);
        box-shadow: $card-box-shadow;

        &+.dismiss {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, .05);
        }
    }

    .bill-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: .5rem;
        border-bottom: 1px solid #ccc;

        .title {
            font-size: 1rem;
            line-height: 2rem;
            margin: 0;
            flex: 0 0 100%;
        }

        .vin {
            font-size: 12px;
            line-height: 2rem;
            margin: 0;
            font-weight: normal;
            flex: 0 0 100%;
        }

        .locate {
            .fa {
                color: $primary;
            }

            flex: 0 0 100%;
            max-width: 100%;
        }

        .call {
            flex: 0 0 var(--mdb-body-line-height);
            line-height: var(--mdb-body-line-height);
            order: 4;

            &+.locate {
                flex: 0 0 calc(100% - var(--mdb-body-line-height) * 1em);
                max-width: calc(100% - var(--mdb-body-line-height) * 1em);
            }
        }
    }

    .bill-list {
        width: 100%;

        td {
            text-align: right;
            width: 1px;
            white-space: nowrap;
        }
    }

    .btns {
        display: flex;
        justify-content: center;
        padding-top: .5rem;
        border-top: 1px solid #ccc;
    }
}

.text-pre {
    white-space: pre;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.dashboard {
    height: calc(100% - .75rem - ($nav-pills-link-padding-top + $nav-pills-link-padding-bottom) - ($nav-pills-link-font-size * $nav-pills-link-line-height));
    overflow: auto;
    --border-color: #eee;
    --border-radius: 4px;
    counter-reset: list;

    .sm {
        display: block;
        width: 3em;
        white-space: normal;
        word-wrap: normal;
        hyphens: auto;
        overflow: hidden;
        text-overflow: ellipsis;

        &.x2 {
            width: 4em;
        }
    }

    .cursor>* {
        cursor: pointer;
    }

    &+.nav {
        justify-content: center;
        margin-top: .75rem;
    }

    .btns {
        display: flex;
        column-gap: .5rem;
        position: sticky;
        left: 0;
        z-index: 20;

        label {
            display: flex;
            white-space: nowrap;
            align-items: center;

            select {
                border: 1px solid var(--border-color);
                margin-left: .5rem;
                border-radius: var(--border-radius);
            }
        }

        &>div {
            display: flex;
            column-gap: .5rem;
        }

        &>a,
        &>div>a {
            padding: 0 .25rem;
        }

        form {
            display: flex;
            flex-direction: row-reverse;
            flex: 0 0 12rem;

            input {
                border-bottom: 1px solid #ccc;
                flex: 0 0 10rem;
                transition: border-color .3s;

                &:focus {
                    border-color: $primary;
                }
            }

            button {
                padding: 0 .25rem;
                color: $primary;
            }
        }
    }

    button:not(.btn) {
        border: none;
        background: none;
        box-shadow: none;

        &:hover,
        &:focus {
            background: none;
            color: var(--mdb-link-hover-color);
        }
    }

    *:not(.modal-body)>.form-outline {
        input {
            padding: 0;
        }

        .form-notch {
            display: none;
        }
    }

    input,
    select {
        width: 100%;
        border: none;
    }

    select {
        padding: .25rem;
    }

    .inventory>[title],
    .bordered {
        position: relative;

        &::after {
            content: " ";
            display: block;
            width: calc(100% - .5rem);
            height: calc(100% - .5rem);
            position: absolute;
            top: 0.25rem;
            left: 0.25rem;
            border: 1px solid var(--border-color);
            pointer-events: none;
            border-radius: var(--border-radius);
        }
    }

    .inventory>[title]:has(.btn)::after {
        display: none !important;
    }

    .tool {
        font-size: 1.2rem;
        line-height: 2rem;
        text-align: center;
        flex: 0 0 2rem;
        width: 2rem;
        height: 2rem;
        padding: 0;
        color: var(--mdb-link-color);

        &::after {
            display: none;
        }
    }

    .dropdown-menu button {
        display: block;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
            font-size: 1.25rem;
        }

        select {
            font-size: 1rem;
            width: 8rem;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
        }
    }

    .card-body {
        overflow: auto;

        &>div {
            min-width: 100%;
        }

        &:has(.sticky) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .inventory {
        --background: #fff;
        display: table-row;
        background-color: var(--background);
        position: relative;

        &.finished {
            --background: #f5f5f5;
        }

        [data-price]:empty:hover::before {
            content: attr(data-price);
            color: #ccc;
            pointer-events: none;
        }

        .row-btns {
            display: table-cell;
            padding: 0 .75rem 0 0;

            &>span {
                display: flex;
                width: 3.25rem;
                justify-content: space-between;
            }

            .btn {
                --mdb-btn-padding-x: .5rem;
                --mdb-btn-padding-top: .25rem;
                --mdb-btn-padding-bottom: .25rem;
                display: block;
                position: relative;

                .fa {
                    position: absolute;
                    right: 2px;
                    bottom: 2px;
                }
            }
        }

        &::before {
            content: "\2003";
            display: block;
            width: 3ch;
            padding: .5rem 0;
            text-align: center;
            position: sticky;
            left: 0;
            z-index: 9;
            background: var(--background);
        }

        &.disabled {
            background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
            color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));

            &>*,
            &::before,
            :disabled {
                background-color: transparent;
            }
        }

        :invalid {
            outline: 2px solid $danger;
        }

        &:not(.head) {
            counter-increment: list;

            &::before {
                content: attr(data-idx);
            }

            &:hover {
                --background: #f7f7f7;
            }
        }

        &>* {
            display: table-cell;
            white-space: nowrap;
            padding: .5rem;

            &:has(.details) {
                padding-right: 2em;
            }

            &:has(.format-datetime-picker) {
                width: 8.5rem;
            }
        }

        .sticky {
            position: sticky;
            background-color: inherit;
            z-index: 9;

            input[type=checkbox] {
                pointer-events: none;
            }

            &:first-child {
                left: 3ch;

                &::after {
                    display: none;
                }
            }

            &:last-child {
                right: 0;
                padding-right: .25rem;
            }

            &:nth-last-child(2) {
                right: 3.5rem;
            }
        }

        .details {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            height: 100%;
            padding: 0 1em 0 .5em;
        }
        
        .priority {
            display: block;
            text-align: center;
            font-size: .75rem;

            &.high {
                font-weight: bold;
                color: #f00;
            }

            &.low {
                font-weight: 100;
                color: #ff8080;
            }
        }
    }

    .head {
        &>* {
            position: relative;
            padding-left: 1rem;
            padding-right: 1rem;

            &>button {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                display: none;
            }

            &:hover>button,
            &:focus>button,
            &>button.active {
                display: block;
            }
        }

        .asc,
        .desc {
            &::after {
                content: " ";
                display: inline-block;
                width: 0;
                height: 0;
                border-width: 5px 4px;
                border-style: solid;
                border-color: currentColor;
                border-top-width: 0;
                border-left-color: transparent;
                border-right-color: transparent;
                vertical-align: .25rem;
                margin-left: .375rem;
            }
        }

        .desc::after {
            border-top-width: 5px;
            border-bottom-width: 0;
        }
    }

    &~.bind.btn {
        position: fixed;
        right: calc(var(--mdb-btn-padding-top) - var(--mdb-btn-padding-x));
        bottom: 5vh;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: var(--mdb-btn-padding-top);
        transition: right .3s;

        &:hover,
        &:focus {
            right: 0;
        }
    }

    .approval {
        li {
            display: flex;
            flex-wrap: wrap;
            gap: .5rem;
            align-items: center;

            .btn {
                --mdb-btn-padding-x: .5rem;
            }
        }

        .author {
            flex: 0 0 10rem;
            max-width: 10rem;
            order: 1;
            margin: 0;
            text-align: right;
            color: #999;
            white-space: nowrap;
        }

        .attr {
            flex: 0 0 calc(100% - 10.5rem);
            max-width: calc(100% - 10.5rem);
            margin: 0;
            order: 0;
        }

        .value {
            flex: 0 0 calc(100% - 7.5rem);
            max-width: calc(100% - 7.5rem);
            order: 2;
        }

        .from {
            color: rgba($primary, 0.3);
        }

        .to {
            color: $primary;

            &::before {
                content: ' -> ';
                color: #ccc;
            }
        }

        .handler {
            flex: 0 0 7rem;
            max-width: 7rem;
            order: 3;
            display: flex;
            align-items: center;
            white-space: nowrap;
            justify-content: flex-end;
        }
    }

    .approving .modified,
    .approving .finished {
        background-color: lighten($primary, 43.5%);
    }
}

.format-datetime-picker {
    position: relative;

    input {
        padding: 1px 2px;
        width: 7.5rem;

        &::-webkit-datetime-edit-fields-wrapper,
        &::-webkit-datetime-edit-text,
        &::-webkit-datetime-edit-minute,
        &::-webkit-datetime-edit-hour-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-year-field {
            color: transparent;
            background: none;
        }
    }

    .changed {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: calc(100% - 2rem);
    }

    span {
        display: block;
        line-height: 1.75;
        position: absolute;
        left: 2px;
        top: 0;
        background: inherit;

        &:only-child {
            position: static;
            line-height: 1.875;
            padding-left: 2px;
        }
    }

    &.p-2 {
        .changed {
            left: .5rem;
            top: .5rem;
        }

        span {
            left: calc(.5rem + 2px);
            top: calc(.5rem + 1px);
        }
    }
}

.job-list {
    --mdb-modal-width: 210mm;

    select {
        border: none;
        width: 100%;
        height: 100%;
        appearance: none;
        background: none;
    }

    .table {
        margin: 0;

        th {
            white-space: nowrap;
            font-weight: normal;
            font-size: 12px;
            height: 1.5em;
            padding-block: 0;
            vertical-align: middle;

            b {
                font-size: 14px;
            }
        }

        td {
            word-break: break-all;
            font-size: 14px;
            line-height: 1.42857143;
            height: 2.85714286em;
            padding-block: 0;
            vertical-align: middle;
        }

        .list {
            border-top-width: 0;

            &+.list:has(:first-child:empty) {
                border-bottom-width: 0;
            }

            &>*:not(:first-child) {
                border-left-width: 0;
            }

            &>*:not(:last-child) {
                border-right-width: 0;
            }
        }
    }
}

.touchevents {

    .resource-picker {
        height: 8rem;

        .btns {
            position: static;
            height: auto;
            display: flex;
            background: none;
        }

        button {
            color: #666;
            border: 1px solid #d5d5d5;
            border-top: none;

            &:not(:first-child) {
                border-left: none;
            }

            &:hover,
            &:focus {
                background: none;
                color: $primary;
            }
        }
    }
}

@include media-breakpoint-up(sm) {

    .edit {
        .accordion .accordion-body>label {
            .form-label {
                visibility: hidden;
            }

            .form-outline .form-notch-middle {
                border-top-color: $form-notch-div-border-color;
            }

            .form-control:focus~.form-notch .form-notch-middle {
                border-top-color: $input-focus-border-color;
                box-shadow: 0 1px 0 0 $input-focus-border-color, 0 -1px 0 0 $input-focus-border-color;
            }

            &>.label {
                display: flex;
            }
        }

        &.was-validated .form-outline .form-control:valid:focus~.form-notch .form-notch-middle,
        &.was-validated .form-outline .form-control:valid.active~.form-notch .form-notch-middle,
        .form-outline .form-control.is-valid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-valid.active~.form-notch .form-notch-middle {
            border-top-color: $success;
        }

        &.was-validated .form-outline .form-control:valid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-valid:focus~.form-notch .form-notch-middle {
            box-shadow: 0 1px 0 0 $success, 0 -1px 0 0 $success;
        }

        &.was-validated .form-outline .form-control:invalid:focus~.form-notch .form-notch-middle,
        &.was-validated .form-outline .form-control:invalid.active~.form-notch .form-notch-middle,
        .form-outline .form-control.is-invalid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-invalid.active~.form-notch .form-notch-middle {
            border-top-color: $danger;
        }

        &.was-validated .form-outline .form-control:invalid:focus~.form-notch .form-notch-middle,
        .form-outline .form-control.is-invalid:focus~.form-notch .form-notch-middle {
            box-shadow: 0 1px 0 0 $danger, 0 -1px 0 0 $danger;
        }
    }
}

@include media-breakpoint-up(md) {

    .header {
        grid-template-columns: auto 1fr;
    }

    .nav-container {
        left: 0;
        width: 3rem;

        .nav-item .nav {
            z-index: -1;

            &.active {
                left: 100%;
            }
        }

        &>.nav>.nav-item>.nav-link {
            text-align: center;
            padding: .5rem 0;

            .tip {
                display: block;
                position: absolute;
                left: calc(100% + .5em);
                top: calc(50% - 1em);
                z-index: 5;
                pointer-events: none;
                opacity: 0;
                transition: opacity .3s;
                white-space: nowrap;
                background-color: #fff;
                font-size: .75rem;
                padding: .5em 1em;
                border-radius: .25em;
                box-shadow: 0 0 .5em rgba(0, 0, 0, .1);

                &::before {
                    content: " ";
                    display: block;
                    width: 0;
                    height: 0;
                    border: .25em solid transparent;
                    border-right-color: #fff;
                    position: absolute;
                    right: 100%;
                    top: calc(50% - .25em);
                }
            }

            &:hover .tip {
                opacity: 1;
            }
        }
    }

    .nav-container+.main-content {
        padding-left: calc(3rem + var(--mdb-gutter-x, .75rem));
    }

    .grid {
        .filter {
            display: block !important;
            height: auto !important;
            border-radius: $card-border-radius;

            form {
                display: inline-flex;
                flex-direction: row;

                .form-outline {
                    width: auto;
                }

                &>* {
                    flex: 0 0 auto;
                }
            }
        }
    }

    .bill .bill-header {

        .title,
        .vin {
            flex: 0 0 auto;
        }
    }
}


@include media-breakpoint-up(lg) {
    .edit {
        flex-direction: row;
        align-items: flex-start;

        .tools {
            flex: 0 0 15rem;
            top: 0;
            bottom: auto;

            .card-body {
                flex-direction: column;
                align-items: stretch;
            }

            .btns {
                flex-direction: column-reverse;
            }
        }
    }
}

// @include media-breakpoint-down(lg) {
//     .dashboard {

//         .card-body {
//             background-color: var(--mdb-gray-100);
//         }

//         .head {
//             display: none !important;
//         }

//         .inventory {
//             display: block;
//             flex: 0 0 calc(50% - .5rem);
//             padding: .75rem;

//             .sticky {
//                 padding-left: .5rem !important;
//                 padding-right: .5rem !important;
//             }

//             [title] {
//                 display: block;
//                 text-align: left !important;

//                 &:not([title=""])::before {
//                     content: attr(title)':';
//                     display: inline-block;
//                     min-width: 7.5rem;
//                     margin-right: .5rem;
//                 }

//                 &:not([title=""])::after {
//                     content: " ";
//                     display: inline-block;
//                     border: none;
//                     width: calc(100% - 8rem);
//                     height: 1px;
//                     background: var(--border-color);
//                     position: absolute;
//                     right: 0;
//                     bottom: .25rem;
//                     left: auto;
//                     top: auto;
//                 }
//             }

//             .btn-block {
//                 width: auto;
//             }

//             input,
//             select {
//                 width: auto;
//                 border: none;

//                 &:not([type=checkbox]) {
//                     min-width: 12rem;
//                 }
//             }

//             .format-datetime-picker {
//                 display: inline-block;

//                 span {
//                     left: 0;
//                     top: 0;

//                     &:only-child {
//                         padding: 0;
//                     }
//                 }
//             }
//         }
//     }
// }

// @include media-breakpoint-down(md) {
//     .dashboard .inventory {
//         display: block;
//         flex: 0 0 100%;
//     }
// }

@media print {

    .header,
    .print-hidden {
        display: none!important;
    }

    .print-block {
        display: block!important;
    }

    .main-content {
        padding-top: var(--mdb-gutter-y, 0.75rem);
    }

    .modal-dialog {
        max-width: none;
        min-height: 100%;
        margin: 0;
    }

    .modal-content {
        box-shadow: none;
    }

    .modal-backdrop {
        opacity: 1 !important;
        background: #fff;
    }

    #root:has(.modal)>:not(.modal) {
        display: none;
    }

    .job-list {
        position: static;
        height: auto;
        overflow: hidden;

        .table {
            --mdb-table-border-color: #000;
        }
    }
}